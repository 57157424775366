<template>
	<releaseDetailsLayout>
		<div class="p-album-details">
			<div class="album_background"
				 v-if="backgroundGradient"
				 :class="{ 'animate-in': gradientReady }"
				 :style="{
					 background: `linear-gradient(to bottom, 
						 ${backgroundGradient} 0%,
						 ${backgroundGradient.replace('1)', '0.8)')} 70%,
						 transparent 100%)`
				 }">
			</div>

			<div class="release_preview" v-if="albumDetails.status && albumDetails.status.value === 'draft'">
				<v-container>
					Release preview. This release will remain as a draft until published.
				</v-container>
			</div>

			<!-- desktop version. D >= 768 -->
			<div class="container album_details_page" v-if="!mobile">

				<!-- Skeleton Loader -->
				<template v-if="infoAlbumLoader && !albumDetails">
					<albumDetailsSkeletonLoader></albumDetailsSkeletonLoader>
				</template>

				<div class="album inside_album">
					<div class="album_content" v-if="albumDetails"
						 :class="{'disabled': albumDetails.status && albumDetails.status.value === 'draft'}">
						<div class="left_box">
							<div class="cover_album">
								<v-skeleton-loader v-if="!albumDetails" type="image" class="cover_release"></v-skeleton-loader>
								<img :src="albumDetails.smallImage" 
									 :alt="albumDetails.title"
									 @click="$refs['modal-image'].show()"/>
								<button type="button" v-ripple class="add_like" v-if="albumDetails.isFavorite === false"
										@click="addAlbumToFavorites(albumDetails)"></button>
								<button type="button" v-ripple class="remove_like" v-if="albumDetails.isFavorite === true"
										@click="removeAlbumFromFavorites(albumDetails)"></button>
								<template>
									<button type="button" v-ripple class="album_play" v-if="!isCurrentPlaying" @click="playAlbum"
											:class="[isCurrentPlaying ? 'current' : 'not_current']"></button>
									<button type="button" v-ripple class="album_play" v-if="isCurrentPlaying && !playing" @click="play"
											:class="[isCurrentPlaying ? 'current' : 'not_current']"></button>
									<button type="button" v-ripple class="album_pause" v-if="isCurrentPlaying && playing" @click="pause"></button>
								</template>
							</div>
						</div>

						<div class="info_album" id="album-details-info">
							<div class="head_album">
								<div class="titles">
									<div class="date mobile">
										{{ albumDetails.releasedAt | moment("LL") }}
									</div>
									<div class="album_title">{{ albumDetails.title }}</div>

									<div class="owner">
										<div class="album_artist" v-if="albumDetails.artist && albumDetails.type !== 'collection'"
										     @click="redirectToArtistPage(albumDetails.artist.id, albumDetails.artist.identifier)">
											<span>Artist: </span> {{ albumDetails.artist.name }}
										</div>
										<div class="album_label" v-if="albumDetails.recordLabel"
										     @click="redirectToLabelProfile(albumDetails.recordLabel.id, albumDetails.recordLabel.identifier)">
											<span>Label:</span> {{ albumDetails.recordLabel.title }}
										</div>

										<div class="album_artist" v-else-if="albumDetails.type === 'collection'">
											<span>By Various Artists</span>
										</div>
										<button
											type="button"
											@click="dialogAreYouThisArtistVisible = true"
											class="is-this-you-link"
											v-if="!isOwner && albumDetails.type !== 'collection'">
											(Is this you?)
										</button>
										<are-you-this-artist-dialog v-if="dialogAreYouThisArtistVisible" :artist="ARTIST"
										                            v-model="dialogAreYouThisArtistVisible">
										</are-you-this-artist-dialog>
									</div>
								</div>
								<div class="actions">

									<div class="date">{{ albumDetails.releasedAt | moment("LL") }}</div>

									<div class="release_purchased" v-if="albumDetails.isPurchased && albumDetails.isPurchased === true"></div>
									<template v-else>
										<div v-if="albumDetails.isFavorite === false"
										     class="album_details_like_button"
										     @click="addAlbumToFavorites(albumDetails)"
										     :class="{ active: albumDetails.isFavorite === true }">
											<svg
												id="heart-svg"
												:class="{ heart_svg: true }"
												width="21"
												height="18"
												viewBox="5 -2 10 16"
												fill="none">
												<path
													id="heart"
													class="heart_path"
													fill="#AAB8C2"
													d="M10.2031 18L8.75313 16.7052C3.60313 12.1243 0.203125 9.103 0.203125 5.3951C0.203125 2.37384 2.62313 0 5.70312 0C7.44312 0 9.11312 0.794551 10.2031 2.05014C11.2931 0.794551 12.9631 0 14.7031 0C17.7831 0 20.2031 2.37384 20.2031 5.3951C20.2031 9.103 16.8031 12.1243 11.6531 16.715L10.2031 18Z"
												/>
											</svg>
										</div>
										<div v-if="albumDetails.isFavorite === true"
										     class="album_details_like_button"
										     @click="removeAlbumFromFavorites(albumDetails)"
										     :class="{ active: albumDetails.isFavorite === true }">
											<svg
												id="heart-svg"
												:class="{ heart_svg: true }"
												width="21"
												height="18"
												viewBox="5 -2 10 16"
												fill="none"
											>
												<path
													id="heart"
													class="heart_path"
													fill="#AAB8C2"
													d="M10.2031 18L8.75313 16.7052C3.60313 12.1243 0.203125 9.103 0.203125 5.3951C0.203125 2.37384 2.62313 0 5.70312 0C7.44312 0 9.11312 0.794551 10.2031 2.05014C11.2931 0.794551 12.9631 0 14.7031 0C17.7831 0 20.2031 2.37384 20.2031 5.3951C20.2031 9.103 16.8031 12.1243 11.6531 16.715L10.2031 18Z"
												/>
											</svg>
										</div>
									</template>


									<albumActions :albumID="albumDetails.id" :albumStatus="albumDetails.status.value" :isOwner="isOwner"></albumActions>

								</div>
							</div>
							<!--END head_album -->

							<div class="tags_list" :class="{edit_tags: isEditTags === true && isAdmin}">
	                            <span v-ripple v-for="tag of albumDetails.tags" :key="tag" @click="selectTag(tag)">{{ tag }}
	                                <button v-if="isEditTags && isAdmin"
	                                        type="button"
	                                        class="btn_delete_tag"
	                                        @click="deleteTag(tag)"
	                                ></button>
	                            </span>

								<span class="new_tag" v-if="showNewTag">
                                    <input type="text" v-model="nameNewTag" ref="actionNewTag" @keydown.enter="addTagToTags"/>
                                </span>
								<button v-if="isEditTags && isAdmin" type="button" class="btn_add_tag" @click="addTag">
									Add Tag
								</button>

								<button type="button" class="btn_edit_tags" v-if="isEditTags === false && isAdmin" @click="isEditTags = true; editTags();">
									Edit
								</button>
								<button type="button" class="btn_edit_tags" v-if="isEditTags === true && isAdmin" @click="isEditTags = false; saveTags();">
									Save
								</button>
							</div>

							<div class="buy_now" v-if="albumDetails.isFavorite || !PLAYED_ONLY_FAV_TRACKS">

								<button type="button" class="c2a_btn btn_buy_album download_album"
								        v-if="albumDetails.isPurchased || albumDetails.isPurchased === true || !albumDetails.price && albumDetails.price !== 0"
								        v-ripple
								        @click="checkPossibilityDownloadAlbum(albumDetails.id, albumDetails.title)">
									Download
								</button>

								<template v-else>
<!--									<v-skeleton-loader v-if="canPurchaseMusicRelease === null" type="image" class="btn_skeleton_buy"></v-skeleton-loader>-->

<!--									<template v-if="canPurchaseMusicRelease && canPurchaseMusicRelease === true">-->
<!--										<template v-if="!albumDetails.isPurchased || albumDetails.isPurchased !== true">-->
											<button type="button" class="c2a_btn btn_buy_album" v-if="albumDetails.price > 0 && albumDetails.inCart === false"
											        v-ripple
											        @click="setAlbumYourPrice('buy')">
												Buy Now ${{ albumDetails.price }}
												<span>or more</span>
											</button>

											<button type="button" class="cart_add_remove none_remove" id="open-header-shopping-cart"
											        v-if="albumDetails.inCart === true"
											        @click="openCart" v-ripple>
												In Cart
											</button>

											<button type="button" class="c2a_btn btn_buy_album" v-if="+albumDetails.price === 0 && albumDetails.inCart === false"
											        v-ripple
											        @click.stop="setAlbumYourPrice('set')">
												Set your price
											</button>
<!--										</template>-->
<!--									</template>-->
								</template>

							</div>


							<!-- description -->
							<template v-if="albumDetails.description !== null && albumDetails.description !== undefined">
								<div class="descript_info text"
								     v-if="albumDetails.description.length < albumDescriptionLength"
								     v-html="albumDetails.description"></div>
								<template v-else>
                                <span class="descript_info text"><p v-html="albumDetails.description.substring(0, albumDescriptionLength)"></p>
                                    <p>...</p>
                                    <p v-if="albumDetails.description.length > albumDescriptionLength" class="see_more" @click="seeMore">See more</p>
                                </span>
								</template>
							</template>
						</div>
					</div>
					<div class="descript_info text mobile" v-html="albumDetails.description"></div>
					<!-- END album_content-->

					<div class="wrap_album_playlist" :class="{'disabled': albumDetails.status && albumDetails.status.value === 'draft'}">
						<album-play-list
							v-if="!infoAlbumLoader"
							:album="albumDetails"
							:detailsTrack="true"
							:autoPlayTrack="+trackID">
						</album-play-list>

						<playlistSkeletonLoader
							v-if="infoAlbumLoader"
							:parent="'album-details-playlist'"
							:count="7"
						></playlistSkeletonLoader>
					</div>


					<template v-if="(albumDetails.owner && albumDetails.owner.id === userID) || isAdmin"><!-- TODO: TEMP blocking -->
						<div class="release_preview_actions" v-if="albumDetails.status && albumDetails.status.value === 'draft'">
							<button type="button" class="tetriatary_btn back" @click="back">Back</button>

							<button type="button" class="primary_btn publish" @click="changeAlbumStatus('pending')">
								<span v-if="!timeoutLoading.publish">Publish</span>
								<v-progress-circular v-if="timeoutLoading.publish" :width="2" indeterminate></v-progress-circular>
							</button>
						</div>
					</template>


				</div>

			</div>


			<!-- mobile -->
			<div class="mobile_album_details" v-if="mobile">
				<template v-if="infoAlbumLoader && !albumDetails">
					<v-container>
						<div class="skeleton">
							<v-skeleton-loader type="image" class="cover_release"></v-skeleton-loader>
							<v-skeleton-loader type="image" class="line_item_track"></v-skeleton-loader>
							<v-skeleton-loader type="image" class="line_item_track"></v-skeleton-loader>
							<v-skeleton-loader type="image" class="line_item_track"></v-skeleton-loader>
							<v-skeleton-loader type="image" class="line_item_track"></v-skeleton-loader>
							<v-skeleton-loader type="image" class="line_item_track"></v-skeleton-loader>
							<v-skeleton-loader type="image" class="line_item_track"></v-skeleton-loader>
							<v-skeleton-loader type="image" class="line_item_track"></v-skeleton-loader>
							<v-skeleton-loader type="image" class="line_item_track"></v-skeleton-loader>
							<v-skeleton-loader type="image" class="line_item_track"></v-skeleton-loader>
							<v-skeleton-loader type="image" class="line_item_track"></v-skeleton-loader>
						</div>
					</v-container>
				</template>
				<template v-if="albumDetails">
					<div class="container">
						<div class="cover_album">
							<v-skeleton-loader v-if="!albumDetails" type="image" class="cover_release"></v-skeleton-loader>
							<img :src="albumDetails.smallImage" 
								 :alt="albumDetails.title"
								 @click="$refs['modal-image'].show()"/>
							<button type="button" v-ripple class="add_like" v-if="albumDetails.isFavorite === false"
									@click="addAlbumToFavorites(albumDetails)"></button>
							<button type="button" v-ripple class="remove_like" v-if="albumDetails.isFavorite === true"
									@click="removeAlbumFromFavorites(albumDetails)"></button>
							<template>
								<button type="button" v-ripple class="album_play" v-if="!isCurrentPlaying" @click="playAlbum"
										:class="[isCurrentPlaying ? 'current' : 'not_current']"></button>
								<button type="button" v-ripple class="album_play" v-if="isCurrentPlaying && !playing" @click="play"
										:class="[isCurrentPlaying ? 'current' : 'not_current']"></button>
								<button type="button" v-ripple class="album_pause" v-if="isCurrentPlaying && playing" @click="pause"></button>
							</template>
						</div>
					</div>

					<album-play-list
						v-if="albumDetails"
						:album="albumDetails"
						:detailsTrack="true"
						:autoPlayTrack="+trackID">
					</album-play-list>

					<div class="container">
						<div class="details_album">
							<div class="album_name">
								{{ albumDetails.title }}
								<button type="button" class="tootle_album_description" @click="toggleAlbumDescription"
										:class="{'expand': albumDescription}">
									<span class="material-icons">expand_more</span>
								</button>
							</div>
							<div class="album_artist" v-if="albumDetails.artist">
								<span>By</span>
								<div v-if="albumDetails.artist" @click="redirectToArtistPage(albumDetails.artist.id, albumDetails.artist.identifier)">
									{{ albumDetails.artist.name }}
								</div>
							</div>
							<div class="album_publish">{{ albumDetails.releasedAt  | moment('LL') }}</div>
							<transition name="expand">
								<div class="album_description" v-html="albumDetails.description" v-if="albumDescription"></div>
							</transition>
						</div>
					</div>
				</template>
			</div>

			<!------------------ Supporters ------------------>
			<div class="supporters" v-if="SUPPORTERS.length && defer(3)">
				<div class="container">
					<h3>Supporters <span class="count">{{ SUPPORTERS.length }}</span></h3>
					<template v-if="SUPPORTERS.length">
						<div class="supporters_cnt" v-for="supporter of SUPPORTERS" :key="supporter.id">

							<!--                    <b-tooltip position="is-top" multilined>-->
							<!--                      <div class="item_supporter"></div>-->
							<!--                      <template v-slot:content>-->
							<!--                        <div class="supporter">-->
							<!--                          <span class="name">Brian Hollister</span>-->
							<!--                          <span class="nick">(McDubbin)</span>-->
							<!--                        </div>-->
							<!--                      </template>-->
							<!--                    </b-tooltip>-->

							<div class="item_supporter">{{ supporter }}</div>
						</div>
						<button class="show_more">Show More</button>
					</template>
					<template v-else>
						<div class="none_supporters">Nothing here yet</div>
					</template>
				</div>
			</div>

			<div class="mobile_tags" v-if="albumDetails.tags">
				<div class="container">
					<h3 class="tags_title">Tags</h3>
					<div class="wrap_tags">
                    <span
	                    v-ripple
	                    v-for="tag of albumDetails.tags"
	                    :key="tag"
	                    @click="selectTag(tag)">{{ tag }}
                    </span>
					</div>
				</div>
			</div>

			<!------------------ Comments ------------------>


				<section class="comments" id="comments-section">
<!--					<template v-if="!infoAlbumLoader">-->
<!--						<div class="container" v-if="albumDetails && (albumDetails.status && albumDetails.status.value !== 'draft')">-->
<!--							<template v-if="albumID && albumDetails.status">-->
<!--								<Comments :albumID="albumID"-->
<!--								          :commentsLocation="albumDetails.status.value === 'published' || albumDetails.status.value === 'grandfathered' ? 'album' : 'album_admin'">-->
<!--								</Comments>-->
<!--							</template>-->
<!--						</div>-->
<!--					</template>-->

					<div class="container" v-if="!infoAlbumLoader">
						<Comments :albumID="albumID"
						          :commentsLocation="albumDetails.status.value === 'published' || albumDetails.status.value === 'grandfathered' ? 'album' : 'album_admin'">
						</Comments>
					</div>
					<div class="container" v-else>
						<v-skeleton-loader
							type="image"
							class="comments_skeleton"
						></v-skeleton-loader>
					</div>

				</section>


			<!------------------ More Artist Albums ------------------>
			<div class="more_from_artist"
			     v-if="moreArtistAlbums.length && (albumDetails.status.value === 'published' || albumDetails.status.value === 'grandfathered') && defer(3)">
				<v-container>
					<div class="_head_section">More from artist</div>
					<div class="wrap_more_albums">
						<albumGrid
							v-for="album in moreArtistAlbums"
							:key="album.id"
							:album="album"
							:class="'small'"
							:playZone="moreArtistAlbums"
							:hideArtist="true">
						</albumGrid>
					</div>
				</v-container>
			</div>

			<!------------------ Modals ------------------>
			<modalImage ref="modal-image" :image="albumDetails.bigImage"/>
			<downloadAlbumDialog v-if="downloadAlbumDialogVisible" v-model="downloadAlbumDialogVisible"></downloadAlbumDialog>
			<dialogNeedLogin v-if="needLoginDialog.visible" v-model="needLoginDialog.visible" :action="needLoginDialog.action"></dialogNeedLogin>
			<setYouAlbumPrice v-if="setYouAlbumPriceDialogVisible"
			                  v-model="setYouAlbumPriceDialogVisible"
			                  :album="albumDetails"
			                  :type="'music_release'"
			                  :setAlbumYourPriceModalVariant="setAlbumYourPriceModalVariant">
			</setYouAlbumPrice>
			<errorDialog v-if="errorDialog" v-model="errorDialog"></errorDialog>
		</div>
	</releaseDetailsLayout>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import { extractColorsFromImage } from '@/utils/imageUtils';

import releaseDetailsLayout from "@/layouts/releaseDetailsLayout.vue";
import albumActions from "@/components/musicReleases/albumActions";

import albumGrid from "@/components/musicReleases/albumGrid";
import albumPlayList from "@/components/musicReleases/albumPlayList";
import headSkeletonLoader from "@/components/small/skeleton-loaders/headSkeletonLoader";
import playlistSkeletonLoader from "@/components/small/skeleton-loaders/playlistSkeletonLoader";

const Comments = () => import("@/components/commentsV2");
const dialogNeedLogin = () => import("@/components/dialogs/needLogin");
const modalImage = () => import("@/components/public/modalImage");
const areYouThisArtistDialog = () => import("@/components/dialogs/areYouThisArtist");

// mixins
import Defer from "@/utils/defer";
import addAlbumToFavorites from "@/mixins/musicLike/addAlbumToFavorites";
import removeAlbumFromFavorites from "@/mixins/musicLike/removeAlbumFromFavorites";
import isMobile from "@/mixins/isMobile";
import album from "@/components/musicReleases/album.vue";

const downloadAlbumDialog = () => import("@/components/dialogs/downloadAlbum");
const setYouAlbumPrice = () => import("@/components/dialogs/setYouAlbumPrice");
const errorDialog = () => import('@/components/dialogs/error');

import albumDetailsSkeletonLoader from "@/components/small/skeleton-loaders/albumDetailsSkeletonLoader.vue";

export default {
	name: "albumDetails",
	props: ["id", 'name', "trackID", "isFavouriteSong", "isFavourite"],
	components: {
		releaseDetailsLayout,
		albumActions,
		albumPlayList,
		albumGrid,
		Comments,
		dialogNeedLogin,
		modalImage,
		areYouThisArtistDialog,
		headSkeletonLoader,
		playlistSkeletonLoader,
		downloadAlbumDialog,
		setYouAlbumPrice,
		errorDialog,
		albumDetailsSkeletonLoader,
	},
	mixins: [
		Defer(),
		addAlbumToFavorites,
		removeAlbumFromFavorites,
		isMobile,
	],
	data() {
		return {
			// overlay: false,
			infoAlbumLoader: false,
			isHidden: true,
			loader: false,
			artistId: null,
			albumID: null,
			albumDetails: "",
			albumDescriptionLength: 500,
			albumDetailsSong: "",
			albumDetailFavouriteSong: "",
			columns: this.$store.getters.SHOW_COLUMNS_TRACK,

			moreArtistAlbums: [],

			// Carousel
			options: {
				rewind: true,
				perPage: 6,
				gap: "50px",
				pagination: false,
			},
			dialogNeedLoginVisible: false,
			needLoginDialog: {
				action: null,
				visible: false,
			},
			downloadAlbumDialogVisible: false,
			setYouAlbumPriceDialogVisible: false,
			coverAlbumDialog: false,
			mobile: false,
			albumDescription: false,

			isOwner: false,
			isEditTags: false,
			showNewTag: false,
			nameNewTag: "",

			tempEditTags: [],
			ownerID: "",
			dialogAreYouThisArtistVisible: false,
			showThankYouVoting: false,
			showAllTracks: false,
			downloadLink: "",

			downloadLimit: 0,
			numberOfDownload: 0,
			downloadsLeft: 0,
			loading: false,
			downloadDisable: false,
			timeoutLoading: {
				publish: false,
				back: false,
				save: false,
			},
			license: false,
			releaseCommerce: null,
			showAddToCartBtn: false,
			timeoutId: null,
			setAlbumYourPriceModalVariant: null,
			ogTitle: '',
			ogDescription: '',
			ogImage: '',
			unpublishedAlbum: false,
			errorDialog: false,
			// canPurchaseMusicRelease: null,
			backgroundGradient: null,
			gradientReady: false,
			gradientCache: new Map(),
		};
	},
	metaInfo() {
		return {
			title: this.ogTitle,
			meta: [
				// Open Graph / Facebook
				{property: 'og:title', content: this.ogTitle},
				{property: 'og:description', content: this.ogDescription},
				{property: 'og:image', content: this.ogImage},
				{property: 'og:type', content: 'music.album'},
				{property: 'og:url', content: window.location.href},
				
				// Twitter Card
				{name: 'twitter:card', content: 'summary_large_image'},
				{name: 'twitter:title', content: this.ogTitle},
				{name: 'twitter:description', content: this.ogDescription},
				{name: 'twitter:image', content: this.ogImage},
				
				// Standard meta
				{name: 'description', content: this.ogDescription},
			],
		}
	},

	computed: {
		album() {
			return album
		},
		...mapGetters([
			'isAdmin',
			"userID", // authData
			"ARTIST",
			"ARTIST_OWNER",
			"AUTH_DATA",
			"ALBUM",
			"ARTIST_ALBUMS",
			"SHOW_COLUMNS_TRACK",
			"SUPPORTERS",
			"EXPLORE_GRID_ALBUMS",
			"FAVOURITE_MUSIC_RELEASE",
			"SEARCH_ALBUMS_RESULT",
			"VIEW_ALBUMS",
			"PLAY_LIST",
			"USER",
			"DISCOVERY",
			"PLAYED_ONLY_FAV_TRACKS",
			"FROM_PAGE",
			"CHECK_MUSIC_RELEASE_DOWNLOAD",
			'UPPER_FILTER',
			'FULL_ALBUM',
			'BASKET_LIST',
			'MUSIC_RELEASE_DOWNLOAD_DATA', 'DOWNLOAD_MUSIC_RELEASE_LINK',
			'USER_PERMISSIONS',
			'CAN_PURCHASE_MUSIC_RELEASE',
			'CAN_PURCHASE_TRACK',
			'COMMENTS_LOADER',
			'FULL_ALBUM_LOADING'
		]),
		...mapGetters({
			currentTrack: "CURRENT_TRACK",
			currentAlbum: "CURRENT_ALBUM",
			playing: "PLAYING",
			playList: "PLAY_LIST",
		}),
		isCurrentPlaying() {
			return (
				!!this.currentAlbum && this.currentAlbum.id === this.albumDetails.id
			);
		},
	},
	watch: {
		id() {
			this.resetState();
			this.getAlbum();
		},
		name() {
			this.resetState();
			this.getAlbum();
		},
		albumDetails: {
			async handler(newAlbum) {
				if (newAlbum?.smallImage) {
					// Set Open Graph meta tags for social sharing
					this.ogTitle = newAlbum.title || 'Music Release';
					this.ogDescription = newAlbum.artist ? `${newAlbum.artist.name} - ${newAlbum.title}` : newAlbum.title;
					this.ogImage = newAlbum.smallImage;
					
					// Start loading the gradient immediately
					const imageUrl = newAlbum.smallImage;
					
					try {
						// If we have cached gradient, use it immediately
						if (this.gradientCache.has(imageUrl)) {
							this.backgroundGradient = this.gradientCache.get(imageUrl);
							this.gradientReady = true;
							return;
						}

						// Otherwise extract colors first, then show
						const colors = await extractColorsFromImage(imageUrl);
						const gradient = colors.topColor;
						this.gradientCache.set(imageUrl, gradient);
						this.backgroundGradient = gradient;
						
						// Show after a very brief delay to ensure gradient is set
						await this.$nextTick();
						this.gradientReady = true;
					} catch (error) {
						console.error('Error generating gradient:', error);
						this.backgroundGradient = null;
					}
				} else {
					this.backgroundGradient = null;
				}
			},
			immediate: true
		}
	},
	created() {
		this.getAlbum();

		if (this.albumDetails.isFavorite === true) {
			for (let i = 0; i < this.albumDetails.playList.length; i++) {
				this.albumDetails.playList[i].song.isFavorite = true;
			}
		}

		this.getExternalLinks();
	},

	mounted() {
		// const albumNameWithSpaces = this.name.replace(/-/g, ' ');
		this.GET_SUPPORTERS().catch((err) => console.log(`GET_SUPPORTERS, ${err}`));
		this.getExternalLinks();
	},
	methods: {
		...mapActions([
			"GET_ARTIST",
			"GET_ARTIST_OWNER",
			"GET_ALBUM",
			"ADD_ALBUM_TO_FAVORITES",
			"REMOVE_ALBUM_FROM_FAVORITES",
			"GET_ARTIST_ALBUMS",
			"GET_SUPPORTERS",
			"GET_USER",
			"CHANGE_ALBUM_TAGS",
			'CHANGE_ALBUM_STATUS',
			'DELETE_MUSIC_RELEASE',
			'GET_CHECK_MUSIC_RELEASE_DOWNLOAD',
			'GET_FULL_ALBUM',
			'GET_DOWNLOAD_MUSIC_RELEASE_LINK',
			'CHECK_CAN_PURCHASE_MUSIC_RELEASE',
			'CHECK_CAN_PURCHASE_TRACK',
		]),
		...mapActions({
			play: "SET_PLAY",
			pause: "SET_PAUSE",
			addTrackToPlaylist: "ADD_TRACK_TO_PLAYLIST",
			addAlbumToBasket: 'ADD_TO_BASKET',
			removeAlbumFromBasket: 'REMOVE_FROM_BASKET',
			getBasket: 'GET_BASKET',
		}),
		...mapActions({
			playlist: "PLAY_LIST",
		}),
		redirectToArtistPage(artistId, artistName) {
			// const name = artistName.trim().replace(/ /g, '-');
			this.$router.push({name: 'artist-profile', params: {id: artistId, name: window.toHumanFormat(artistName)}});
		},
		redirectToLabelProfile(labelId, labelName) {
			this.$router.push({name: 'label-profile', params: {id: labelId, name: window.toHumanFormat(labelName)}});
		},

		// GET MAIN DATA
		async getAlbum() {
			this.infoAlbumLoader = true;
			this.loader = true;

			try {
				let params = {id: this.id, name: this.name};
				await this.GET_FULL_ALBUM(params);
				
				if (!this.FULL_ALBUM.id) {
					this.$router.push({name: "404"});
					return;
				}
				
				this.albumDetails = this.FULL_ALBUM;
				this.albumID = this.albumDetails.id;

				// Handle track autoplay
				if (this.trackID !== undefined) {
					let track = this.FULL_ALBUM.playList.filter((track) => +track.id === +this.trackID);
					let index = this.FULL_ALBUM.playList.findIndex((track) => +track.id === +this.trackID);
					this.play({
						fromPlay: "album_default",
						track: track[0].song,
						index: index,
						album: this.albumDetails,
						isDiscoveryStatus: this.DISCOVERY,
					});
				}

				if (this.userID) {
					this.checkUserIsOwnerAlbum();
				}

				await this.getMoreArtistAlbums();
			} catch (err) {
				console.error(`albumDetails, getAlbum, ${err}`);
				this.$router.push({name: "404"});
			} finally {
				this.infoAlbumLoader = false;
				this.loader = false;
			}
		},
		setAlbumYourPrice(val) {
			if (this.AUTH_DATA.accessToken) {
				this.setAlbumYourPriceModalVariant = val;
				this.sypAlbumSelected = this.musicRelease;
				this.setYouAlbumPriceDialogVisible = true;
			} else {
				// this.dialogNeedLoginVisible = true;
				this.needLoginDialog = {
					action: 'set_your_price',
					visible: true,
				};
			}
		},

		openCart() {
			if (this.AUTH_DATA.accessToken) {
				this.$store.commit('SET_HEADER_SHOPPING_CART', false);
				window.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
			} else {
				// this.dialogNeedLoginVisible = true;
				this.needLoginDialog = {
					action: 'open_cart',
					visible: true,
				};
			}
		},

		async getMoreArtistAlbums() {
			if (!this.albumDetails.artist) {
				return;
			}

			try {
				let params = {
					artist: this.albumDetails.artist.id,
					status: 'published'
				};
				
				await this.GET_ARTIST_ALBUMS(params);
				this.moreArtistAlbums = this.ARTIST_ALBUMS.filter(
					(album) => album.id !== this.albumDetails.id
				);
			} catch (err) {
				console.error(`getMoreArtistAlbums error: ${err}`);
				this.moreArtistAlbums = [];
			}
		},
		checkUserIsOwnerAlbum() {
			if (this.albumDetails.owner && this.albumDetails.owner.id) {
				let ownerID = this.albumDetails.owner.id;
				if (+this.userID === +ownerID) {
					this.isOwner = true;
				}
			}
		},

		seeMore() {
			this.albumDescriptionLength = this.albumDetails.description.length;
			setTimeout(() => {
				this.getExternalLinks();
			}, 500);
		},
		getExternalLinks() {
			if (this.albumDetails.description && this.albumDetails.description.length) {
				let box = document.getElementsByClassName("descript_info")[0];
				if (box) {
					let links = box.getElementsByTagName("A");
					if (links.length) {
						for (let i = 0; i < links.length; i++) {
							links[i].target = "_blank";
						}
					}
				}
			}
		},

		// mobile
		playAlbum() {
			if (!this.isCurrentPlaying) {
				this.play({
					fromPlay: "album_default",
					track: this.albumDetails.playList[0].song,
					index: 0,
					album: this.albumDetails,
					isDiscoveryStatus: this.DISCOVERY,
				});
			}
		},

		checkPossibilityDownloadAlbum(id, title) {
			let albumDeletedAt = this.albumDetails.deletedAt;
			let albumHavePurchased = this.albumDetails.isPurchased;

			if (this.AUTH_DATA.accessToken) {
				this.$store.commit('SET_MUSIC_RELEASE_DOWNLOAD_DATA', {id: id, title: title})

				if (albumHavePurchased && albumHavePurchased === true || this.albumDetails.owner && this.albumDetails.owner.id === this.userID) {
					this.directDownload();
				} else if (!albumDeletedAt || (albumDeletedAt && albumHavePurchased === true)) {
					this.GET_CHECK_MUSIC_RELEASE_DOWNLOAD()
						.then(() => {
							let remain = this.CHECK_MUSIC_RELEASE_DOWNLOAD.limit - this.CHECK_MUSIC_RELEASE_DOWNLOAD.downloaded;
							if (remain <= 10) {
								this.downloadAlbumDialogVisible = true;
							} else {
								this.directDownload();
							}
						})
				} else {
					this.errorDialog = true;
					console.log('download failure');
				}
			} else {
				// this.dialogNeedLoginVisible = true;
				this.needLoginDialog = {
					action: 'download_album',
					visible: true,
				};
			}
		},
		directDownload() {
			this.GET_DOWNLOAD_MUSIC_RELEASE_LINK(this.MUSIC_RELEASE_DOWNLOAD_DATA.id)
				.then(() => {
					if (this.DOWNLOAD_MUSIC_RELEASE_LINK) {
						let link = document.createElement('a');
						link.href = this.DOWNLOAD_MUSIC_RELEASE_LINK;
						link.download = `${this.MUSIC_RELEASE_DOWNLOAD_DATA.title}`;
						link.click();
					} else {
						this.errorDialog = true;
					}
				})
				.catch(err => {
					console.error(`download-purchased, download music-release, ${err}`);
				})
		},


		// edit-save tags
		selectTag(tag) {
			if (this.isEditTags) {
				return;
			}
			// this.$store.dispatch('UPDATE_SUBGENRE', {});
			// this.$store.dispatch('UPDATE_BPM', []);
			// this.$store.dispatch('UPDATE_KEYS', []);
			this.$store.dispatch("UPDATE_TAGS", [tag]);
			this.$store.commit("SET_ALBUM_EXPANDED", null);
			this.$store.commit('SET_REFRESH_RELEASES', true);
			this.$router.push({name: "explore"});
		},

		editTags() {
			this.tempEditTags = this.albumDetails.tags;
		},
		addTag() {
			this.showNewTag = true;
			this.$nextTick(() => {
				this.$refs.actionNewTag.focus();
			});
		},
		addTagToTags() {
			if (this.nameNewTag.length > 1) {
				this.albumDetails.tags.push(this.nameNewTag);
				this.showNewTag = false;
				this.nameNewTag = "";
			}
		},
		deleteTag(tag) {
			this.albumDetails.tags = this.albumDetails.tags.filter(
				(tags) => tags !== tag
			);
		},
		saveTags() {
			let params = {
				id: this.albumDetails.id,
				tags: this.albumDetails.tags,
			};
			this.CHANGE_ALBUM_TAGS(params)
				.then(() => {
					console.log("CHANGE_ALBUM_TAGS - success");
				})
				.catch((err) => console.log(`CHANGE_ALBUM_TAGS, ${err}`));
		},

		toggleAlbumDescription() {
			this.albumDescription = !this.albumDescription;
		},

		markUndiscovered(album) {
			album.isListened = false;
		},

		back() {
			this.$router.push({name: 'createMusicRelease', params: {id: this.id, editRelease: true}});
			this.$store.commit('SET_CURRENT_STEP', 3);
		},
		changeAlbumStatus(status, comment) {
			//  [ "draft", "pending", "rejected", "approved", "need_review" ]
			if (this.AUTH_DATA.accessToken) {
				this.loading = true;
				this.timeoutLoading.publish = true;
				let params = {
					id: this.id,
					status: status,
					// comment: !comment ? `album ${this.id} change status to pending` : comment,
				}
				this.CHANGE_ALBUM_STATUS(params)
					.then(() => {
						this.$router.push({name: 'musicReleaseSubmitted'});
					})
					.catch(err => {
						console.error(`changeAlbumStatus, ${err}`);
					})
					.finally(() => {
						this.loading = false;
						this.timeoutLoading.publish = false;
					});
			} else {
				// this.dialogNeedLoginVisible = true;
				this.needLoginDialog = {
					action: 'change_status',
					visible: true,
				};
			}
		},
		resetState() {
			this.infoAlbumLoader = true;
			this.loader = true;
			this.albumDetails = "";
			this.albumID = null;
			this.moreArtistAlbums = [];
			this.backgroundGradient = null;
			this.gradientReady = false;
			this.gradientCache = new Map();
		},
	},
};
</script>

<style lang="scss">
.release-details-layout {
	position: relative;
}

.p-album-details {
	position: relative;

	.album_background {
		position: absolute;
		top: -200px;
		left: 0;
		width: 100%;
		height: 600px;
		pointer-events: none;
		z-index: 1;
		opacity: 0;
		transform: translateY(-50px);

		&.animate-in {
			animation: slideDown 0.8s cubic-bezier(0.2, 0, 0.1, 1) forwards;
		}
	}
}

@keyframes slideDown {
	0% {
		opacity: 0;
		transform: translateY(-50px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.cover_album {
    width: 100%;
    position: relative;
    margin-bottom: 24px;
    aspect-ratio: 1;
    border-radius: 12px;
    overflow: hidden;

    .cover_release {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #1A1F34;
        border-radius: 12px;
        z-index: 1;

        // Fix Vuetify skeleton loader dimensions
        &.v-skeleton-loader {
            height: 100% !important;
            width: 100% !important;

            .v-skeleton-loader__image {
                height: 100% !important;
                width: 100% !important;
                min-height: 100% !important;
            }
        }
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
        z-index: 2;
        user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;

        @media (min-width: 768px) {
            cursor: pointer;
        }
    }

    .album_play,
    .album_pause,
    .add_like,
    .remove_like {
        position: absolute;
        z-index: 3;
    }
}

// Fix initial skeleton loader dimensions
.skeleton {
    .cover_release.v-skeleton-loader {
        aspect-ratio: 1;
        width: 100% !important;
        height: auto !important;
        border-radius: 12px;

        .v-skeleton-loader__image {
            height: 100% !important;
            width: 100% !important;
            aspect-ratio: 1;
            border-radius: 12px !important;
        }
    }

    .line_item_track {
        margin-top: 12px;
        height: 60px !important;
        border-radius: 12px;

        &:first-child {
            margin-top: 24px;
        }

        .v-skeleton-loader__image {
            height: 100% !important;
            border-radius: 12px !important;
        }
    }
}

.wrap_album_playlist {
    margin-top: 24px;

    .v-skeleton-loader {
        height: 60px !important;
        margin-bottom: 12px;
        border-radius: 12px;

        .v-skeleton-loader__image {
            height: 100% !important;
            border-radius: 12px !important;
        }
    }
}

.gradient-fade-enter-active,
.gradient-fade-leave-active {
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.gradient-fade-enter-from,
.gradient-fade-leave-to {
    opacity: 0;
    transform: translateY(-100px);
}

.gradient-fade-enter-to,
.gradient-fade-leave-from {
    opacity: 1;
    transform: translateY(0);
}

// Add appear classes
.gradient-fade-appear {
    opacity: 0;
    transform: translateY(-100px);
}

.gradient-fade-appear-active {
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.gradient-fade-appear-to {
    opacity: 1;
    transform: translateY(0);
}

@keyframes fadeUpImage {
    0% {
        opacity: 0;
        transform: scale(0.95);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
</style>
